<template>
    <div class="news_item">
        <div class="banner">
            <img src="~@/assets/images/xinweni_banner.png" alt="">
        </div>
        <div class="content">
            <div class="inner">
                <div class="path">
                    <div class="img">
                        <img src="~@/assets/images/icon_weizhi.png" alt="">
                    </div>
                    <el-breadcrumb separator="/">
                        <el-breadcrumb-item :to="{ path: '/company-news' }">企业动态</el-breadcrumb-item>
                        <el-breadcrumb-item> 资讯详情</el-breadcrumb-item>
                    </el-breadcrumb>
                </div>
                <div class="news_content">
                    <div class="title">{{ newsDetails.title }}</div>
                    <div class="info">
                        <div class="info_inner">
                            <div class="info_item">
                                <img src="~@/assets/images/icon_liulan.png" alt="">
                                <span>浏览：336次</span>
                            </div>
                            <div class="info_item">
                                <img src="~@/assets/images/shijian_icon.png" alt="">
                                <span>发布时间：{{ newsDetails.sortsTime }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="mainbody" v-html="newsDetails.content"></div>
                    <div class="btn_box">
                        <a href="javascript:;" class="btn" v-if="newsDetails.params&&newsDetails.params.prePage" @click="handleGetnewsDetails(newsDetails.params.prePage.id)">上一篇：{{ newsDetails.params.prePage.title }}</a>
                        <a href="javascript:;" class="btn" v-if="newsDetails.params&&newsDetails.params.nextPage" @click="handleGetnewsDetails(newsDetails.params.nextPage.id)">上一篇：{{ newsDetails.params.nextPage.title }}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getNewsDetails } from '@/api/aboutus'
import $ from 'jquery'
    export default {
        name:'NewItem',
        data(){
            return {
                newsDetails:{}
            }
        },
        watch:{
            '$route.fullPath':{
                handler(val){
                    this.getNewsDetails()
                    console.log(val)
                },
                immediate:true
            }
        },
        methods:{
            getNewsDetails(){
                getNewsDetails(this.$route.params.id).then(res=>{
                    this.newsDetails = res.data
                })
            },
            handleGetnewsDetails(id){
                this.$router.push({name:"NewsItem",params:{id}})
            }
        },
        updated() {
            $('.mainbody').find('img').css({
                "width": "auto",
                "height": "auto",
                'margin': '0 auto',
            });
        }
    }
</script>

<style lang="scss" scoped>
.news_item{
    .path{
        display: flex;
        .img{
            margin-right: 8px;
        }
        margin-bottom: 55px;
    }
    .content{
        padding-top: 59px;
        padding-bottom: 62px;
    }
    .news_content{
        .title{
            font-size: 26px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #222222;
            margin-bottom: 32px;
            text-align: center;
        }
        .info{
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 8px 0;
            background-color: #F5F5FA;
            margin-bottom: 46px;
            .info_inner{
                display: flex;

                .info_item{
                    display: flex;
                    align-items: center;
                    margin: 0 20px;
                    font-size: 14px;
                    font-family: PingFang SC;
                    font-weight: 500;
                    color: #999999;
                    span{
                        line-height: 1;
                    }
                    img{
                        margin-right: 5px;
                    }
                }
            }
        }
        .mainbody{
            padding-bottom: 60px;
            border-bottom: 1px solid #99999933;
            margin-bottom: 29px;
        }
        .btn_box{
            a{
                display: block;
                color: #666;
                font-size: 14px;
                margin-bottom: 15px;
                &:hover{
                    text-decoration: underline;
                    color: #005FC6;
                }
            }
        }
    }

}
</style>
